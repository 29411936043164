import * as React from "react";

import { NavbarContext } from "../utils/navbar";

export function NavbarProvider({ children }: { children: React.ReactElement }) {
  // State
  const [isActive, setActive] = React.useState(false);

  // Value
  const navbarContextValue = React.useMemo(
    () => ({
      isActive,
      setActive,
    }),
    [isActive],
  );

  return <NavbarContext.Provider value={navbarContextValue}>{children}</NavbarContext.Provider>;
}
