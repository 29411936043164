import AsyncStorage from "@react-native-async-storage/async-storage";
import * as React from "react";
import { t } from "i18next";

import { TherapistProfileResponse, UserResponse } from "@bo/types";

import { usePostAuthLoginRequest } from "../screens/auth/service";
import { getMeRequest, getTherapistProfileRequest } from "../services/accountService";
import { AuthContext } from "../utils/auth";
import Toast from "react-native-toast-message";

export function AuthProvider({ children }: { children: React.ReactElement }) {
  const [user, setUser] = React.useState<UserResponse>();
  const [therapistProfile, setTherapistProfile] = React.useState<TherapistProfileResponse>();
  const [isLoading, setIsLoading] = React.useState(true);

  const setAccessToken = (token: string | undefined) => {
    setIsLoading(true);
    const operation = token ? AsyncStorage.setItem("access_token", token) : AsyncStorage.removeItem("access_token");
    operation
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Error handling the access token:", error);
        setIsLoading(false);
      });
  };

  const setRefreshToken = (token: string | undefined) => {
    const operation = token ? AsyncStorage.setItem("refresh_token", token) : AsyncStorage.removeItem("refresh_token");
    operation.catch((error) => console.error("Error handling the refresh token:", error));
  };

  const { data: getMeResult, refetch: refetchMe } = getMeRequest();
  const { data: getTherapistProfileResult, refetch: refetchTherapistProfile } = getTherapistProfileRequest(
    user?.id ?? "",
  );

  const { mutate: login } = usePostAuthLoginRequest(
    (error) => {
      if (error.response) {
        Toast.show({
          type: "error",
          text1: t("toasts.default-error-title"),
          text2: "Napačno uporabniško ime ali geslo.",
        });
      } else {
        Toast.show({
          type: "error",
          text1: t("toasts.default-error-title"),
          text2: t("toasts.error.default-error-message", { description: "prijavi" }),
        });
      }

      setIsLoading(false);
    },
    (result) => {
      setIsLoading(false);
      const acceptedRoles = ["ADMIN", "THERAPIST"];
      if (!acceptedRoles.includes(result.data.user.role)) {
        Toast.show({
          type: "error",
          text1: t("toasts.default-error-title"),
          text2: "Uporabnik nima pravic za dostop do aplikacije.",
        });
        return;
      }
      setAccessToken(result.data.accessToken);
      setRefreshToken(result.data.refreshToken);
      setUser(result.data.user);
      console.log(result.data); // TODO: remove this line
    },
  );

  const logout = () => {
    setIsLoading(true);
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setUser(undefined);
    setIsLoading(false);
  };

  React.useEffect(() => {
    setIsLoading(true);
    AsyncStorage.getItem("access_token")
      .then((token) => {
        if (token) {
          return refetchMe(); // Return the promise from refetchMe() if token exists
        }
        return null; // Explicitly return null when there's no token
      })
      .then(() => {
        setIsLoading(false); // Will run after refetchMe() or immediately after null return
      })
      .catch((error) => {
        console.error("Error during the access token fetch or refetch operation:", error);
        setIsLoading(false); // Ensure loading is set to false even if there's an error
      });
  }, []);

  React.useEffect(() => {
    if (!getMeResult) {
      return;
    }
    setUser(getMeResult.data);
  }, [getMeResult]);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    refetchTherapistProfile();
  }, [user]);

  React.useEffect(() => {
    if (!getTherapistProfileResult) {
      return;
    }
    setTherapistProfile(getTherapistProfileResult.data);
  }, [getTherapistProfileResult]);

  const authContextValue = React.useMemo(
    () => ({
      user,
      therapistProfile,
      isLoading,
      setUser,
      setTherapistProfile,
      setAccessToken,
      login,
      logout,
      refetchMe,
    }),
    [user, therapistProfile, isLoading],
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}
