import { AxiosError, AxiosResponse } from "axios";
import { privateRequestClient } from "../utils/request";
import { useMutation, useQuery } from "@tanstack/react-query";
import { MessageResponse, MessageCreateRequest } from "@bo/types";

export function usePostMessageRequest(
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<MessageResponse>) => void,
) {
  return useMutation<AxiosResponse<MessageResponse>, AxiosError, MessageCreateRequest>({
    mutationKey: ["POST", "messages"],
    mutationFn: (data) => privateRequestClient.post("/messages", data),
    onError,
    onSuccess,
  });
}

export function useGetMessages(appointmentId: string) {
  return useQuery<AxiosResponse<MessageResponse[]>, AxiosError>({
    queryKey: ["GET", "messages", appointmentId],
    queryFn: () => privateRequestClient.get(`/messages/${appointmentId}`),
    enabled: false,
  });
}

export function useDeleteMessageRequest(
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<MessageResponse>) => void,
) {
  return useMutation<AxiosResponse<MessageResponse>, AxiosError, string>({
    mutationKey: ["DELETE", "messages"],
    mutationFn: (id) => privateRequestClient.delete(`/messages/${id}`),
    onError,
    onSuccess,
  });
}
