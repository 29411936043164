import React from "react";
import { DyteProvider, useDyteClient } from "@dytesdk/react-web-core";
import Loading from "./Loading";
import Facetime from "./Facetime";
import { NavigationParamList } from "../../navigation/RootStack";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

export default function Call({ route }: NativeStackScreenProps<NavigationParamList, "Call">) {
  const [meeting, initMeeting] = useDyteClient();

  const token = route.params?.token;

  React.useEffect(() => {
    const init = async () => {
      initMeeting({
        authToken: token,
        defaults: {
          audio: true,
          video: true,
        },
      });
    };
    init();
  }, []);

  React.useEffect(() => {
    if (!meeting) return;
    meeting.joinRoom();
  }, [meeting]);

  return (
    <DyteProvider value={meeting} fallback={<Loading />}>
      {meeting ? <Facetime /> : <Loading />}
    </DyteProvider>
  );
}
