import { AxiosError, AxiosResponse } from "axios";
import { privateRequestClient } from "../utils/request";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ScheduleResponse, ScheduleUpsertRequest } from "@bo/types";

export function useGetSchedules() {
  return useQuery<AxiosResponse<ScheduleResponse[]>, AxiosError>({
    queryKey: ["GET", "schedules"],
    queryFn: () => privateRequestClient.get("/schedules"),
    enabled: false,
  });
}

export function usePostSchedulesRequest(
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<ScheduleResponse[]>) => void,
) {
  return useMutation<AxiosResponse<ScheduleResponse[]>, AxiosError, ScheduleUpsertRequest>({
    mutationKey: ["POST", "schedules"],
    mutationFn: (data) => privateRequestClient.post("/schedules", data),
    onError,
    onSuccess,
  });
}
