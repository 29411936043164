import * as React from "react";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { ScrollView, StyleSheet, View } from "react-native";

import { background } from "@bo/constants/colors";

import Button from "@bo/components/atom/Button";
import Input from "@bo/components/atom/Input";
import Text from "@bo/components/common/Text";
import { useAuthContext } from "../../hooks/authHook";

const styles = StyleSheet.create({
  holder: {
    flexGrow: 1,
    backgroundColor: background,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
  },
  innerHolder: {
    padding: 32,
    flexGrow: 1,
    justifyContent: "space-around",
    gap: 48,
    maxWidth: 650,
  },
  title: {
    marginBottom: 64,
  },
  inputs: {
    marginBottom: 16,
  },
  resetPassword: {
    color: "#C5C5C5",
  },
  buttonView: {
    gap: 16,
  },
  mainButton: {
    marginBottom: 16,
  },
});

export default function LoginScreen() {
  const { login } = useAuthContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  React.useEffect(() => {
    // trigger form input validation on mount to make button disabled by defualt
    trigger();
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.holder}>
      <View style={styles.innerHolder}>
        <View>
          <Text type="H3" style={styles.title}>
            {t("screens.auth.login.login-title")}
          </Text>

          <View style={styles.inputs}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange } }) => (
                <Input placeholder={t("screens.auth.login.input-email")} onChange={onChange} />
              )}
              name="email"
            />
          </View>

          <View style={styles.inputs}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange } }) => (
                <Input placeholder={t("screens.auth.login.input-password")} onChange={onChange} isPassword={true} />
              )}
              name="password"
            />
          </View>
          {/* <Text type="P2" style={styles.resetPassword} textAlign="center">
            {t("screens.auth.login.forgot-password")}
          </Text> */}
        </View>

        <View style={styles.buttonView}>
          <View style={styles.mainButton}>
            <Button type="primary" onPress={handleSubmit(login)} isDisabled={Object.keys(errors).length > 0}>
              {t("screens.auth.login.login-button")}
            </Button>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}
