import * as React from "react";
import { Pressable, StyleSheet } from "react-native";
import { useNavigation, NavigationProp } from "@react-navigation/native";

import Text from "@bo/components/common/Text";
import { NumberBubble } from "@bo/components/atom/NumberBubble";
import { primaryDark } from "@bo/constants/colors";
import { borderRadiusCircular } from "@bo/constants/variables";
import { useNavbarContext } from "../../../../hooks/navbarHook";

const styles = StyleSheet.create({
  container: {
    padding: 8,
    borderRadius: borderRadiusCircular,
    borderWidth: 2,
    borderColor: "transparent",
    alignItems: "center",
  },
  containerSelected: {
    borderColor: primaryDark,
  },
  labelSelected: {
    color: primaryDark,
  },
});

interface Props {
  route: string;
  label: string;
  numberBubbleCount?: number;
}

export default function DashboardNavBarItem({ route, label, numberBubbleCount }: Props) {
  const navigation = useNavigation<NavigationProp<any>>();
  const { setActive } = useNavbarContext();

  const isSelected = navigation.getState().routes.at(-1)?.name === route;

  const navigate = () => {
    setActive(false);
    navigation.navigate(route);
  };

  return (
    <Pressable style={isSelected ? [styles.container, styles.containerSelected] : styles.container} onPress={navigate}>
      <NumberBubble count={numberBubbleCount} insetRight={-16} insetTop={-4}>
        <Text type="P1.B" textAlign="center" style={isSelected ? styles.labelSelected : undefined}>
          {label}
        </Text>
      </NumberBubble>
    </Pressable>
  );
}
