import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { t } from "i18next";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: 18,
    fontWeight: "bold",
  },
});

export default function Loading() {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{t("screens.dashboard.call.loading")}</Text>
    </View>
  );
}
