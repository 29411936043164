import * as React from "react";
import { t } from "i18next";

import {
  usePostCreateMeetingRequest,
  usePostAddParticipantRequest,
  getNextAppointment,
  usePutAppointmentRequest,
} from "./service";
import Button from "@bo/components/atom/Button";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import Toast from "react-native-toast-message";

interface Props {
  userId: string;
  image?: string;
  name: string | null;
  buttonText: string;
  role: "THERAPIST" | "USER";
  isDisabled: boolean;
}

export default function JoinVideoCallButton({ name, role, image, buttonText, userId, isDisabled }: Props) {
  const navigation = useNavigation<NavigationProp<any>>();
  const [isLoading, setIsLoading] = React.useState(false);

  const { data: nextAppointment, refetch: fetchNextAppointment } = getNextAppointment();

  const { mutate: updateAppointment } = usePutAppointmentRequest(nextAppointment?.data.id ?? "", () => {
    Toast.show({
      type: "error",
      text1: t("toasts.default-error-title"),
      text2: t("toasts.default-error-message", { description: "povezovanju na klic" }),
    });
    setIsLoading(false);
  });

  const { mutate: addParticipant } = usePostAddParticipantRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "povezovanju na klic" }),
      });
      setIsLoading(false);
    },
    (data) => {
      setIsLoading(false);
      if (data) {
        navigation.navigate("Call", { token: data.data.data.token });
      } else {
        Toast.show({
          type: "error",
          text1: t("toasts.default-error-title"),
          text2: t("toasts.default-error-message", { description: "povezovanju na klic" }),
        });
      }
    },
  );

  const handleAddParticipant = (meetingId: string) => {
    addParticipant({
      meetingId,
      name: name ?? "Someone",
      picture: image
        ? image
        : "https://www.shutterstock.com/image-vector/user-profile-icon-vector-avatar-600nw-2247726673.jpg",
      role,
      customParticipantId: userId,
    });
  };

  const { mutate: createMeeting } = usePostCreateMeetingRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "povezovanju na klic" }),
      });
    },
    (data) => {
      if (data.data.success) {
        updateAppointment({
          callMeetingId: data.data.data.id,
        });
        handleAddParticipant(data.data.data.id);
      } else {
        Toast.show({
          type: "error",
          text1: t("toasts.default-error-title"),
          text2: t("toasts.default-error-message", { description: "povezovanju na klic" }),
        });
      }
    },
  );

  const onJoinCallPressed = () => {
    if (isLoading || isDisabled) {
      return;
    }

    return fetchNextAppointment()
      .then(({ data: freshAppointment }) => {
        setIsLoading(true);

        if (freshAppointment?.data) {
          if (freshAppointment.data.callMeetingId) {
            handleAddParticipant(freshAppointment.data.callMeetingId);
          } else {
            createMeeting({
              title: "Bo Appointment",
              appointmentId: freshAppointment?.data.id || "",
            });
          }
        }
      })
      .catch(() => {
        Toast.show({
          type: "error",
          text1: t("toasts.default-error-title"),
          text2: t("toasts.default-error-message", { description: "posodabljanju termina srečanja" }),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Button type="primary" onPress={onJoinCallPressed} isLoading={isLoading} isDisabled={isDisabled}>
      {buttonText}
    </Button>
  );
}
