import * as React from "react";

import { useAuthContext } from "../hooks/authHook";
import { downloadAsset } from "../services/assetsService";
import { ProfileImageContext, ProfileImageContextValue } from "@bo/hooks";

export function ProfileImageProvider({ children }: { children: React.ReactElement }) {
  // State
  const { user } = useAuthContext();

  // Query
  const { data, refetch } = downloadAsset(user?.profileImageAssetId ?? "");

  // Effects
  React.useEffect(() => {
    if (user?.profileImageAssetId) {
      refetch();
    }
  }, [user?.profileImageAssetId]);

  // Value
  const profileImageContextValue = React.useMemo(() => {
    if (!data) {
      return null;
    }

    const value: ProfileImageContextValue = {
      assetId: user!.profileImageAssetId!,
      data: data.data,
    };

    return value;
  }, [data]);

  return <ProfileImageContext.Provider value={profileImageContextValue}>{children}</ProfileImageContext.Provider>;
}
