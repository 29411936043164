import * as React from "react";
import { View, ViewStyle, StyleSheet } from "react-native";

import DashboardHeader from "./components.tsx/Header";
import DashboardNavBar from "./components.tsx/NavBar";
import DashboardContentContainer from "./components.tsx/ContentContainer";

const styles = StyleSheet.create({
  holder: {
    backgroundColor: "white",
    height: "100%",
  },
  horizontalFragmentsHolder: {
    flexGrow: 1,
    flexDirection: "row",
  },
});

interface Props {
  children: React.ReactNode;
  contentContainerStyle?: ViewStyle;
}

export default function DashboardTemplate({ children, contentContainerStyle }: Props) {
  return (
    <View style={styles.holder}>
      <DashboardHeader />
      <View style={styles.horizontalFragmentsHolder}>
        <DashboardNavBar />
        <DashboardContentContainer contentContainerStyle={contentContainerStyle}>{children}</DashboardContentContainer>
      </View>
    </View>
  );
}
