import { use } from "i18next";
import { initReactI18next } from "react-i18next";

import { translation as enTranslation } from "./en";
import { translation as slTranslation } from "./sl";

export const resources = {
  en: {
    translation: enTranslation,
  },
  sl: {
    translation: slTranslation,
  },
};

use(initReactI18next).init({
  compatibilityJSON: "v3",
  lng: "sl",
  resources,
});
