import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { AuthResponse, PostAuthLoginRequest } from "@bo/types";

import { publicRequestClient } from "../../utils/request";

export function usePostAuthLoginRequest(
  onError: (error: AxiosError) => void,
  onSuccess: (data: AxiosResponse<AuthResponse>) => void,
) {
  return useMutation<AxiosResponse<AuthResponse>, AxiosError, PostAuthLoginRequest>({
    mutationKey: ["POST", "auth", "login"],
    mutationFn: (data) => publicRequestClient.post("/auth/login", data),
    onError,
    onSuccess,
  });
}
