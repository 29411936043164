import * as React from "react";
import { t } from "i18next";
import { View, StyleSheet } from "react-native";
import { textLight } from "@bo/constants/colors";

import DashboardTemplate from "./template/Template";
import Text from "@bo/components/common/Text";
import Button from "@bo/components/atom/Button";
import { ScheduleCard } from "@bo/components/molecule/ScheduleCard";
import { TimePickerValue } from "@bo/components/molecule/ScheduleCard/interface";
import { useGetSchedules, usePostSchedulesRequest } from "../../services/scheduleService";
import { ScheduleUpsertRequest } from "@bo/types";
import { useIsMobile } from "../../hooks/mobileHook";
import Toast from "react-native-toast-message";

const desktopStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 64,
    paddingBottom: 32,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingHorizontal: 64,
    paddingTop: 32,
    gap: 32,
  },
  daySection: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 16,
    paddingBottom: 32,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
});

const mobileStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 32,
    paddingBottom: 16,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
    gap: 16,
  },
  daySection: {
    alignItems: "center",
    gap: 8,
    paddingBottom: 16,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
});

export default function ScheduleScreen() {
  const isMobile = useIsMobile();

  const styles = isMobile ? mobileStyles : desktopStyles;

  const [timeslots, setTimeslots] = React.useState<[TimePickerValue | null, TimePickerValue | null][][]>([
    [],
    [],
    [],
    [],
    [],
  ]);

  const { refetch: getSchedules, data: schedules, error: schedulesError } = useGetSchedules();
  const { mutate: updateSchedules } = usePostSchedulesRequest(
    () => {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "posodabljanju urnika" }),
      });
    },
    () => {
      Toast.show({
        type: "success",
        text1: t("toasts.default-success-title"),
        text2: t("toasts.default-success-message", { description: "posodobil-a urnik" }),
      });
    },
  );

  const handleAddClick = (dayIndex: number) => {
    const newTimeslots = [...timeslots];
    newTimeslots[dayIndex].push([
      { hour: 0, minutes: 0 },
      { hour: 0, minutes: 0 },
    ]);
    setTimeslots(newTimeslots);
  };

  const handleDelete = (dayIndex: number, index: number) => {
    const newTimeslots = [...timeslots];
    newTimeslots[dayIndex].splice(index, 1);
    setTimeslots(newTimeslots);
  };

  const handleTimeFromChange = (dayIndex: number, index: number, value: TimePickerValue | null) => {
    const newTimeslots = [...timeslots];
    newTimeslots[dayIndex][index][0] = value;
    setTimeslots(newTimeslots);
  };

  const handleTimeToChange = (dayIndex: number, index: number, value: TimePickerValue | null) => {
    const newTimeslots = [...timeslots];
    newTimeslots[dayIndex][index][1] = value;
    setTimeslots(newTimeslots);
  };

  const handleSave = () => {
    const request: ScheduleUpsertRequest = {
      schedules: timeslots.flatMap((timeslot, dayIndex) => {
        return timeslot.map((value) => {
          return {
            dayIndex: dayIndex + 1,
            fromHour: value[0]?.hour ?? 0,
            fromMinutes: value[0]?.minutes ?? 0,
            toHour: value[1]?.hour ?? 0,
            toMinutes: value[1]?.minutes ?? 0,
          };
        });
      }),
    };

    updateSchedules(request);
  };

  React.useEffect(() => {
    getSchedules();
  }, []);

  React.useEffect(() => {
    if (!schedules) {
      return;
    }

    const newTimeslots: typeof timeslots = [[], [], [], [], []];

    schedules.data.forEach((schedule) => {
      const index = schedule.dayIndex - 1; // handle sunday being 0

      newTimeslots[index].push([
        { hour: schedule.fromHour, minutes: schedule.fromMinutes },
        { hour: schedule.toHour, minutes: schedule.toMinutes },
      ]);
    });

    setTimeslots(newTimeslots);
  }, [schedules]);

  React.useEffect(() => {
    if (schedulesError) {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "pridobivanju urnika" }),
      });
    }
  }, [schedulesError]);

  return (
    <DashboardTemplate>
      <View style={styles.titleContainer}>
        <Text type="H3">{t("screens.dashboard.schedule.title")}</Text>
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.daySection}>
          <Text type="P1.B">{t("screens.dashboard.schedule.monday")}</Text>
          {timeslots[0].map((value, index) => (
            <ScheduleCard
              key={index}
              value={value}
              onDelete={() => handleDelete(0, index)}
              onFromChange={(value) => handleTimeFromChange(0, index, value)}
              onToChange={(value) => handleTimeToChange(0, index, value)}
            />
          ))}
          <Button type="secondary" onPress={() => handleAddClick(0)}>
            {t("screens.dashboard.schedule.add")}
          </Button>
        </View>
        <View style={styles.daySection}>
          <Text type="P1.B">{t("screens.dashboard.schedule.tuesday")}</Text>
          {timeslots[1].map((value, index) => (
            <ScheduleCard
              key={index}
              value={value}
              onDelete={() => handleDelete(1, index)}
              onFromChange={(value) => handleTimeFromChange(1, index, value)}
              onToChange={(value) => handleTimeToChange(1, index, value)}
            />
          ))}
          <Button type="secondary" onPress={() => handleAddClick(1)}>
            {t("screens.dashboard.schedule.add")}
          </Button>
        </View>
        <View style={styles.daySection}>
          <Text type="P1.B">{t("screens.dashboard.schedule.wednesday")}</Text>
          {timeslots[2].map((value, index) => (
            <ScheduleCard
              key={index}
              value={value}
              onDelete={() => handleDelete(2, index)}
              onFromChange={(value) => handleTimeFromChange(2, index, value)}
              onToChange={(value) => handleTimeToChange(2, index, value)}
            />
          ))}
          <Button type="secondary" onPress={() => handleAddClick(2)}>
            {t("screens.dashboard.schedule.add")}
          </Button>
        </View>
        <View style={styles.daySection}>
          <Text type="P1.B">{t("screens.dashboard.schedule.thursday")}</Text>
          {timeslots[3].map((value, index) => (
            <ScheduleCard
              key={index}
              value={value}
              onDelete={() => handleDelete(3, index)}
              onFromChange={(value) => handleTimeFromChange(3, index, value)}
              onToChange={(value) => handleTimeToChange(3, index, value)}
            />
          ))}
          <Button type="secondary" onPress={() => handleAddClick(3)}>
            {t("screens.dashboard.schedule.add")}
          </Button>
        </View>
        <View style={styles.daySection}>
          <Text type="P1.B">{t("screens.dashboard.schedule.friday")}</Text>
          {timeslots[4].map((value, index) => (
            <ScheduleCard
              key={index}
              value={value}
              onDelete={() => handleDelete(4, index)}
              onFromChange={(value) => handleTimeFromChange(4, index, value)}
              onToChange={(value) => handleTimeToChange(4, index, value)}
            />
          ))}
          <Button type="secondary" onPress={() => handleAddClick(4)}>
            {t("screens.dashboard.schedule.add")}
          </Button>
        </View>
        <Button type="primary" onPress={handleSave}>
          {t("screens.dashboard.schedule.button")}
        </Button>
      </View>
    </DashboardTemplate>
  );
}
