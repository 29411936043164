import * as React from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import { textLight } from "@bo/constants/colors";

import DashboardTemplate from "./template/Template";
import Text from "@bo/components/common/Text";
import { ActivityCard } from "@bo/components/molecule/ActivityCard";
import { NotificationResponse } from "@bo/types";
import { useGetNotifications, usePutMarkNotificationAsRead } from "../../services/notificationsService";
import { useUnreadNotificationsContext } from "../../hooks/unreadNotificationsCountHook";
import { useIsMobile } from "../../hooks/mobileHook";
import Toast from "react-native-toast-message";

const desktopStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 64,
    paddingBottom: 32,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingTop: 32,
    gap: 32,
  },
  divider: {
    borderColor: textLight,
    borderBottomWidth: 1,
  },
});

const mobileStyles = StyleSheet.create({
  titleContainer: {
    paddingHorizontal: 32,
    paddingBottom: 16,
    borderColor: textLight,
    borderBottomWidth: 1,
  },
  contentContainer: {
    paddingTop: 16,
    gap: 16,
  },
  divider: {
    borderColor: textLight,
    borderBottomWidth: 1,
  },
});

export default function NotificationsScreen() {
  const { i18n } = useTranslation();
  const locale = i18n.language === "sl" ? "sl-SI" : "en-US";
  const isMobile = useIsMobile();

  const styles = isMobile ? mobileStyles : desktopStyles;

  const [notifications, setNotifications] = React.useState<NotificationResponse[]>([]);

  const { count, setCount } = useUnreadNotificationsContext();

  const { refetch: fetchNotifications, data: notificationData, error: notificationError } = useGetNotifications();
  const { mutate: markNotificationAsRead } = usePutMarkNotificationAsRead(() => {
    Toast.show({
      type: "error",
      text1: t("toasts.default-error-title"),
      text2: t("toasts.default-error-message", { description: "označevanju obvestila kot prebranega" }),
    });
  });

  React.useEffect(() => {
    fetchNotifications();
  }, []);

  React.useEffect(() => {
    if (notificationError) {
      Toast.show({
        type: "error",
        text1: t("toasts.default-error-title"),
        text2: t("toasts.default-error-message", { description: "pridobivanju obvestil" }),
      });
    }
  }, [notificationError]);

  React.useEffect(() => {
    if (notificationData) {
      setNotifications(notificationData.data);
      setCount(notificationData.data.filter((notification) => !notification.read).length);
    }
  }, [notificationData]);

  const handleOnPress = (index: number) => {
    if (notifications[index].read) {
      return;
    }

    const newNotifications = [...notifications];
    newNotifications[index].read = true;

    markNotificationAsRead(newNotifications[index].id);
    setNotifications(newNotifications);
    setCount(count - 1);

    console.log(newNotifications[index]);
  };

  return (
    <DashboardTemplate>
      <View style={styles.titleContainer}>
        <Text type="H3">{t("screens.dashboard.notifications.title")}</Text>
      </View>
      <View style={styles.contentContainer}>
        {notifications?.map((notification, index) => (
          <>
            <ActivityCard
              key={index}
              title={notification.title ?? t("screens.dashboard.notifications.notification")}
              description={`${new Date(notification.time).toLocaleDateString(locale)} ${new Date(
                notification.time,
              ).toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit" })} - ${notification.text}`}
              icon={<View />}
              onCheckedChange={() => handleOnPress(index)}
              checked={notification.read}
              isCheckboxDisabled={notification.read}
            />
            {index + 1 < notifications.length && <View style={styles.divider} />}
          </>
        ))}
      </View>
    </DashboardTemplate>
  );
}
