import * as React from "react";
import { functions } from "@bo/constants";
import { theme } from "@bo/constants/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./providers/AuthProvider";
import { UnreadNotificationsCountProvider } from "./providers/UnreadNotificationsCountProvider";
import { ProfileImageProvider } from "./providers/ProfileImageProvider";
import { NavbarProvider } from "./providers/NavbarProvider";
import { PaperProvider } from "react-native-paper";
import RootStack from "./navigation/RootStack";
import { toastConfig } from "@bo/components/atom/ToastTemplate";

import "./translations/config";
import Toast from "react-native-toast-message";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

export default function App() {
  React.useEffect(() => {
    async function prepare() {
      try {
        await functions.loadAssetsAsync();
      } catch (e) {
        console.warn(e);
      }
    }

    prepare();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <PaperProvider theme={theme}>
        <AuthProvider>
          <ProfileImageProvider>
            <NavbarProvider>
              <UnreadNotificationsCountProvider>
                <RootStack />
              </UnreadNotificationsCountProvider>
            </NavbarProvider>
          </ProfileImageProvider>
        </AuthProvider>
      </PaperProvider>
      {/* <Toast config={toastConfig} />  */}
    </QueryClientProvider>
  );
}
