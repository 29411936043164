import { AxiosResponse, AxiosError } from "axios";
import { AppointmentResponse, AppointmentUpdateRequest } from "@bo/types/appointments";
import { privateRequestClient } from "../utils/request";
import { useQuery, useMutation } from "@tanstack/react-query";

export function useGetAppointments() {
  return useQuery<AxiosResponse<AppointmentResponse[]>, AxiosError>({
    queryKey: ["GET", "appointments"],
    queryFn: () => privateRequestClient.get("/appointments"),
    enabled: false,
  });
}

export function useGetAppointmentById(id: string) {
  return useQuery<AxiosResponse<AppointmentResponse>, AxiosError>({
    queryKey: ["GET", "appointments", id],
    queryFn: () => privateRequestClient.get(`/appointments/${id}`),
    enabled: false,
  });
}

export function usePutAppointmentRequest(
  id: string,
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<AppointmentResponse>) => void,
) {
  return useMutation<AxiosResponse<AppointmentResponse>, AxiosError, AppointmentUpdateRequest>({
    mutationKey: ["PUT", "appointments", id],
    mutationFn: (data) => privateRequestClient.put(`/appointments/${id}`, data),
    onError,
    onSuccess,
  });
}
