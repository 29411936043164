import { AxiosError, AxiosResponse } from "axios";
import { privateRequestClient } from "../utils/request";
import { useMutation, useQuery } from "@tanstack/react-query";
import { TherapistProfileResponse, TherapistProfileUpsertRequest, UserResponse, UserUpdateMeRequest } from "@bo/types";

export function usePutUserMeRequest(
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<UserResponse>) => void,
) {
  return useMutation<AxiosResponse<UserResponse>, AxiosError, UserUpdateMeRequest>({
    mutationKey: ["PUT", "users"],
    mutationFn: (data) => privateRequestClient.put("/users", data),
    onError,
    onSuccess,
  });
}

export function usePostTherapistProfileMeRequest(
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<TherapistProfileResponse>) => void,
) {
  return useMutation<AxiosResponse<TherapistProfileResponse>, AxiosError, TherapistProfileUpsertRequest>({
    mutationKey: ["POST", "therapist-profile"],
    mutationFn: (data) => privateRequestClient.post("/therapist-profile", data),
    onError,
    onSuccess,
  });
}

export function getMeRequest() {
  return useQuery<AxiosResponse<UserResponse>, AxiosError>({
    queryKey: ["GET", "users", "me"],
    queryFn: () => privateRequestClient.get("/users/me"),
    enabled: false,
  });
}

export function getTherapistProfileRequest(id: string) {
  return useQuery<AxiosResponse<TherapistProfileResponse>, AxiosError>({
    queryKey: ["GET", "therapist-profile", id],
    queryFn: () => privateRequestClient.get(`/therapist-profile/${id}`),
    enabled: false,
  });
}
