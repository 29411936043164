import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  PostCreateMeetingRequest,
  PostAddParticipantRequest,
  CreateMeetingApiResponse,
  AddParticipantApiResponse,
} from "@bo/types";
import { AppointmentResponse, AppointmentUpdateRequest } from "@bo/types/appointments";
import { privateRequestClient } from "../../../utils/request";

export function usePostCreateMeetingRequest(
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<CreateMeetingApiResponse>) => void,
) {
  return useMutation<AxiosResponse<CreateMeetingApiResponse>, AxiosError, PostCreateMeetingRequest>({
    mutationKey: ["POST", "call", "create-meeting"],
    mutationFn: (data) => privateRequestClient.post("/call/create-meeting", data),
    onError,
    onSuccess,
  });
}

export function usePostAddParticipantRequest(
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<AddParticipantApiResponse>) => void,
) {
  return useMutation<AxiosResponse<AddParticipantApiResponse>, AxiosError, PostAddParticipantRequest>({
    mutationKey: ["POST", "call", "add-participant"],
    mutationFn: (data) => privateRequestClient.post("/call/add-participant", data),
    onError,
    onSuccess,
  });
}

export function usePutAppointmentRequest(
  id: string,
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<AppointmentResponse>) => void,
) {
  return useMutation<AxiosResponse<AppointmentResponse>, AxiosError, AppointmentUpdateRequest>({
    mutationKey: ["PUT", "appointments", id],
    mutationFn: (data) => privateRequestClient.put(`/appointments/${id}`, data),
    onError,
    onSuccess,
  });
}

export function getNextAppointment() {
  return useQuery<AxiosResponse<AppointmentResponse>, AxiosError>({
    queryKey: ["GET", "appointments", "next"],
    queryFn: () => privateRequestClient.get("/appointments/next"),
    enabled: false,
  });
}
