import * as React from "react";

import { UnreadNotificationsCountContext } from "../utils/notifications";

import { useGetUnreadNotificationsCount } from "../services/notificationsService";

export function UnreadNotificationsCountProvider({ children }: { children: React.ReactElement }) {
  // State
  const [count, setCount] = React.useState(0);

  // Query
  const { data, refetch } = useGetUnreadNotificationsCount();

  // Effect
  React.useEffect(() => {
    refetch();
  }, []);

  React.useEffect(() => {
    if (!data) {
      return;
    }

    setCount(data.data.count);
  }, [data]);

  // Value
  const authContextValue = React.useMemo(
    () => ({
      count,
      setCount,
    }),
    [count],
  );

  return (
    <UnreadNotificationsCountContext.Provider value={authContextValue}>
      {children}
    </UnreadNotificationsCountContext.Provider>
  );
}
