import { API_URL } from "@env";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Axios, { AxiosError } from "axios";
import { Buffer } from "buffer";

import { AuthRefreshResponse, PostAuthRefreshRequest } from "@bo/types";

const getPublicRequestClient = () => {
  const client = Axios.create({
    baseURL: API_URL,
  });

  return client;
};

const decodeToken = (token: string) => {
  const parts = token
    .split(".")
    .map((part) => Buffer.from(part.replace(/-/g, "+").replace(/_/g, "/"), "base64").toString());
  return JSON.parse(parts[1]);
};

const refreshToken = async () => {
  const refresh = await AsyncStorage.getItem("refresh_token");
  const access = await AsyncStorage.getItem("access_token");

  if (!refresh || !access) {
    throw new Error();
  }

  const payload: PostAuthRefreshRequest = {
    refreshToken: refresh,
    accessToken: access,
  };

  const publicClient = getPublicRequestClient();
  const refreshResponse = await publicClient.post<AuthRefreshResponse>("/auth/refresh", payload);

  await AsyncStorage.setItem("access_token", refreshResponse.data.accessToken);

  return refreshResponse.data.accessToken;
};

const getPrivateRequestClient = () => {
  const client = Axios.create({
    baseURL: API_URL,
  });

  client.interceptors.request.use(async (config) => {
    const newConfig = { ...config };

    const token = await AsyncStorage.getItem("access_token");

    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  });

  client.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      if (error.response?.status !== 401) {
        throw error;
      }

      const { config } = error;

      if (!config) {
        throw error;
      }

      const token = await AsyncStorage.getItem("access_token");

      if (!token) {
        throw error;
      }

      let newAccessToken: string;

      try {
        const decodedToken = decodeToken(token);

        // if token is not expired, throw error and don't refresh
        if (decodedToken.exp && Date.now() < decodedToken.exp * 1000) {
          throw error;
        }

        newAccessToken = await refreshToken(); // use var for function scope instead of const or let for block scope
      } catch {
        throw error;
      }

      config.headers.Authorization = `Bearer ${newAccessToken}`;

      return client(config);
    },
  );

  return client;
};

export const publicRequestClient = getPublicRequestClient();
export const privateRequestClient = getPrivateRequestClient();
