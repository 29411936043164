import { AxiosError, AxiosResponse } from "axios";
import { privateRequestClient } from "../utils/request";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AssetCreateRequest, AssetResponse } from "@bo/types";

export function downloadAsset(id: string) {
  return useQuery<AxiosResponse<Blob>, AxiosError>({
    queryKey: ["GET", "assets", id, "download"],
    queryFn: () => privateRequestClient.get(`/assets/${id}/download`, { responseType: "blob" }),
    enabled: false,
  });
}

export function usePostAssetRequest(
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<AssetResponse>) => void,
) {
  return useMutation<AxiosResponse<AssetResponse>, AxiosError, AssetCreateRequest>({
    mutationKey: ["POST", "assets"],
    mutationFn: (data) => {
      const formData = new FormData();

      formData.append("file", data.file);
      formData.append("name", data.name);

      return privateRequestClient.post("/assets", formData, { headers: { "Content-Type": "multipart/form-data" } });
    },
    onError,
    onSuccess,
  });
}
