import { CountResponse, NotificationResponse } from "@bo/types";
import { AxiosError, AxiosResponse } from "axios";
import { privateRequestClient } from "../utils/request";
import { useMutation, useQuery } from "@tanstack/react-query";

export function useGetNotifications() {
  return useQuery<AxiosResponse<NotificationResponse[]>, AxiosError>({
    queryKey: ["GET", "notifications"],
    queryFn: () => privateRequestClient.get("/notifications"),
    enabled: false,
  });
}

export function useGetUnreadNotificationsCount() {
  return useQuery<AxiosResponse<CountResponse>, AxiosError>({
    queryKey: ["GET", "notifications", "unread-count"],
    queryFn: () => privateRequestClient.get("/notifications/unread-count"),
    enabled: false,
  });
}

export function usePutMarkNotificationAsRead(
  onError?: (error: AxiosError) => void,
  onSuccess?: (data: AxiosResponse<NotificationResponse>) => void,
) {
  return useMutation<AxiosResponse<NotificationResponse>, AxiosError, string>({
    mutationKey: ["PUT", "notifications", "mark-as-read"],
    mutationFn: (id) => privateRequestClient.put(`/notifications/mark-as-read/${id}`, {}),
    onError,
    onSuccess,
  });
}
