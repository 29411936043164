import React, { StyleSheet, View, TouchableOpacity } from "react-native";

import {
  DyteAudioVisualizer,
  DyteAvatar,
  DyteNameTag,
  DyteParticipantTile,
  DyteParticipantsAudio,
  DyteSimpleGrid,
} from "@dytesdk/react-ui-kit";
import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core";
import Draggable from "react-draggable";
import { Mic, Video, X } from "react-feather";
import Text from "@bo/components/common/Text";
import { Black, White, background, videoCallButtons } from "@bo/constants/colors";
import { borderRadiusCircular } from "@bo/constants/variables";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { t } from "i18next";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: background,
    padding: 16,
  },
  participantTile: {
    position: "absolute",
    right: 8,
    top: 8,
    zIndex: 10,
    width: 160,
    elevation: 4,
    shadowColor: Black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.6,
    shadowRadius: 2,
  },
  gridContainer: {
    position: "relative",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  controlBar: {
    zIndex: 20,
    paddingTop: 16,
    justifyContent: "space-evenly",
    padding: 16,
  },
  controlBarContainer: {
    zIndex: 20,
    width: 450,
    alignSelf: "center",
  },
  buttonCommon: {
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    borderRadius: borderRadiusCircular,
    marginHorizontal: 4,
  },
  buttonActive: {
    backgroundColor: videoCallButtons.active,
    color: Black,
  },
  buttonInactive: {
    backgroundColor: videoCallButtons.inactive,
    color: White,
  },
  buttonDisabled: {
    backgroundColor: videoCallButtons.disabled,
  },
  buttonLeave: {
    backgroundColor: videoCallButtons.leave,
    color: White,
  },
  gridText: {
    padding: 16,
    textAlign: "center",
    fontSize: 20,
    marginTop: 64,
  },
  flexContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  text: {
    color: White,
    marginVertical: 6,
  },
  titlesHolder: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function Facetime() {
  const { meeting } = useDyteMeeting();

  return (
    <View style={styles.container}>
      <DyteParticipantsAudio meeting={meeting as any} />
      <Grid />
      <Draggable bounds="parent">
        <DyteParticipantTile
          participant={meeting.self as any}
          meeting={meeting as any}
          key={meeting.self.id}
          style={styles.participantTile}
        >
          <DyteAvatar participant={meeting.self} size="md" />
          <DyteNameTag participant={meeting.self as any} size="md">
            <DyteAudioVisualizer participant={meeting.self as any} size="md" slot="start" />
          </DyteNameTag>
        </DyteParticipantTile>
      </Draggable>

      <Controlbar />
    </View>
  );
}

function Grid({ ref }: any) {
  const { meeting } = useDyteMeeting();
  const participants = useDyteSelector((m) => m.participants.active);

  return (
    <View style={styles.gridContainer}>
      {participants.size === 0 && (
        <Text style={styles.gridText}>{t("screens.dashboard.call.waiting-placeholder")}</Text>
      )}
      {participants.size > 0 && (
        <DyteSimpleGrid ref={ref} participants={participants.toArray() as any} meeting={meeting as any} />
      )}
    </View>
  );
}

function Controlbar() {
  const navigation = useNavigation<NavigationProp<any>>();
  const { meeting } = useDyteMeeting();

  const { videoEnabled, audioEnabled } = useDyteSelector((m) => ({
    videoEnabled: m.self.videoEnabled,
    audioEnabled: m.self.audioEnabled,
  }));

  async function toggleCamera() {
    if (meeting.self.videoEnabled) {
      await meeting.self.disableVideo();
    } else {
      await meeting.self.enableVideo();
    }
  }

  async function toggleMic() {
    if (meeting.self.audioEnabled) {
      await meeting.self.disableAudio();
    } else {
      await meeting.self.enableAudio();
    }
  }

  async function leaveMeeting() {
    await meeting.leaveRoom();
    navigation.navigate("Appointments");
  }

  return (
    <View style={styles.controlBarContainer}>
      <View style={styles.controlBar}>
        <View style={styles.flexContainer}>
          <TouchableOpacity
            style={[styles.buttonCommon, audioEnabled ? styles.buttonActive : styles.buttonInactive]}
            onPress={toggleMic}
          >
            <Mic />
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.buttonCommon, videoEnabled ? styles.buttonActive : styles.buttonInactive]}
            onPress={toggleCamera}
          >
            <Video />
          </TouchableOpacity>
          <TouchableOpacity style={[styles.buttonCommon, styles.buttonLeave]} onPress={leaveMeeting}>
            <X />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
